<template> 
    <v-layout justify-center class="pt-9" fill-height>  
        <!-- <v-btn color="success" @click="generatePDF()">PDF</v-btn> -->
        <mbs-page-spinner v-if="!Joined_SaleOrder" />  
        <!-- <mbs-epson-display-temp1 v-else
            :show="true"
            :loading="inputLoading"
            :print_request="DAY_SHIFT_PRINT_REQUEST"/>  -->
            <!-- 8.5*96 
            210mm;
            --> 
        <v-card 
            outlined tile style="width: 210mm;" 
            :min-height="10*96"
            class="pa-5"
            >
            <v-card ref="print_template" id="print_template" height="100%" color="transparent" flat>

                <v-layout justify-center align-center column fill-height class="ma-0 ">  
                    <v-card width="100%" flat> 
        
                        <!-- //HEADERS -->
                        <div v-if="Company" class="no_table">
                            <table style="width:100%;" class="no-border ">
                                <tr class="">
                                    <th >
                                        <div> 
                                            <!-- <v-img @load="IMAGE_LOADED" @loadstart="IMAGE_START_LOADING" 
                                                v-if="Company.company_logo" contain width="180" class=""
                                                src="/logo/companies/electro_sales.png"
                                                /> -->
                                            <v-img @load="IMAGE_LOADED" @loadstart="IMAGE_START_LOADING" 
                                                v-if="Company.company_logo" contain width="180" class=""
                                                :src="Company.company_logo"
                                                /> 
                                            <v-img v-else  contain width="160" class="" src="\system\no_img.png"/> 
                                        </div> 
                                    </th>  
                                    <td  >
                                        <div class="text-center mx-3">
                                            <div class="not-f4- font-weight-bold" v-if="Company.company_name">{{Company.company_name}}</div> 
                                            <div class="not-f3- font-weight-normal" v-if="Company.company_email_address">Email: {{Company.company_email_address}}</div> 
                                            <div class="not-f3- font-weight-normal"  v-if="Company.company_post_address">Address: {{Company.company_post_address}}</div> 
                                            <v-layout justify-center class="not-f3-" v-if="Company.company_phone_number"><div class="mr-2">Phone: {{Company.company_phone_number}}</div><div v-if="Company.company_phone_number2">,{{Company.company_phone_number2}}</div></v-layout>  
                                            <div class="not-f3- font-weight-normal" v-if="Company.outlet_name">Outlet: {{SaleOrder.outlet_name}}</div> 
                                            <div class="not-f3- font-weight-normal" v-if="Company.company_tpin">TPIN: {{Company.company_tpin}}</div> 
                                        </div>
                                    </td>  
                                    <th >
                                        <div class="my-1"  justify-center> 
                                            <mbs-barcode
                                                :value="MBS.actions.TEXT_UP(SaleOrder.order_type.slice(0,2))+SaleOrder.key"
                                                :options="{
                                                    height:50,
                                                    width:1.2,
                                                    displayValue: true,
                                                    margin:0
                                                }"
                                            />
                                        </div> 
                                    </th>  
                                </tr> 
                            </table> 
                        </div> 
                        
                        <v-card outlined width="100%" class=" mt-9 b-all" tile  >
                            <v-layout fill-height class="ma-0 pa-2 font-weight-bold" justify-center align-center>
                              {{title?title:'CASH SALE / INVOICE'}}
                            </v-layout>
                        </v-card>

                        
                        <div v-if="false" class=" ">
                            <table style="width:100%;" class=" ">
                                <tr class="">
                                    <th >
                                        <div class="m-font">
                                            <div class="m-font">Customer Name:{{ SaleOrder.customer_name }}</div>
                                            <div>Phone Number:</div>
                                            <div>Email Address:</div>
                                        </div>
                                    </th>  

                                    <th  >
                                        
                                    </th>  
                                    <th >
                                        <div class="my-1"  justify-center> 
                                            <div class="text-right">
                                                <div>Date: {{ SaleOrder.local_created_at_ }}</div>
                                                <div>Till: {{ SaleOrder.till_name }} <span>, {{ SaleOrder.teller_name  }}</span></div>
                                                <div>{{MBS.actions.TEXT_UP11(SaleOrder.order_type)}} Number:{{SaleOrder.order_id}}</div>
                                                <div>Invoice key:{{SaleOrder.key}}</div>
                                            </div>
                                        </div> 
                                    </th>  
                                </tr> 
                            </table> 
                        </div> 
        
                        
                        <!-- //document headers -->
                        <div class="my-4" v-if="true">
                            <v-layout class="ma-0 not-f">
                                <div> 
                                    <div>Customer Name:{{ SaleOrder.customer_name }}</div>
                                    <div>Phone Number:</div>
                                    <div>Email Address:</div>
                                </div>
                                <v-spacer></v-spacer>
                                <div class="text-right">
                                    <div>Date: {{ SaleOrder.local_created_at_ }}</div>
                                    <div>Till: {{ SaleOrder.till_name }} <span>, {{ SaleOrder.teller_name  }}</span></div>
                                    <div>{{MBS.actions.TEXT_UP11(SaleOrder.order_type)}} Number: {{MBS.actions.NUMBER_SAME_LENGTH(SaleOrder.order_id,'4')}}</div> 
                                </div>
                            </v-layout>
                        </div>
        
                        <!-- //ITEMS -->
                        <div v-if="Joined_SaleOrderItems">  
                            <div class=" items_table not-f3"  >   
                                <table style="width:100%" >
                                    <!-- //header -->
                                    <tr class="b-all ">
                                        <th style="width:;">NO</th> 
                                        <th style="width:43">ITEM NAME</th> 
                                        
                                        <th style="width:14%">PRICE</th>  
                                        <th style="width:14%">DISC</th> 
                                        <th style="width:10%">QTY</th> 
                                        <th style="width:18%">AMOUNT (EX VAT)</th>  
                                        <!-- colspan="2"  -->
                                    </tr>  

                                    <!-- //items -->
                                    <tr class="borders b-x" v-for="(item,index) in Joined_SaleOrderItems"
                                            :key="index" >
                                        <td  class="text-right px-2">{{index+1}}</td> 
                                        <td  class="px-2">{{item.item_name}}</td> 
                                        <td  class="text-right px-2">{{item.sold_price_}}</td> 
                                        <td  class="text-right px-2">{{MBS.actions.money(item.discount)}}</td> 
                                        <td  class="text-right px-2">{{item.quantity}}</td> 
                                        <td  class="text-right px-2">{{MBS.actions.money(PriceExcludeVAT(item))}} 
                                            <span class="ml-2"> {{item.item.tax_group_code?item.item.tax_group_code:"A"}}</span>
                                        </td>    
                                    </tr> 
                                    <tr class="borders b-x" v-for="(item,index) in FixItemSize(20)" >
                                        <!-- <td class="px- text-right white--text">{{index}}</td>  -->
                                        <td class="px- text-right white--text">.</td> 
                                        <td></td> 
                                        <td></td> 
                                        <td></td> 
                                        <td></td> 
                                        <td> </td>  
                                    </tr> 



                                    <tr class="b-all">
                                        <th colspan="2">SUB-TOTAL</th>
                                        <td colspan="4" class="text-right px-2">
                                            <span v-if="CalculatedVAT"> 
                                                <span v-if="CalculatedVAT.vat_exclusive"> 
                                                    {{ MBS.actions.money(toNumber(SaleOrder.total_sold_price?SaleOrder.total_sold_price:SaleOrder.total_price)+toNumber(SaleOrder.all_discounts?SaleOrder.all_discounts:SaleOrder.total_discount)) }} 
                                                </span>
                                                <span v-else>
                                                    {{ MBS.actions.money(toNumber(SaleOrder.total_sold_price?SaleOrder.total_sold_price:SaleOrder.total_price)-toNumber(SaleOrder.all_discounts?SaleOrder.all_discounts:SaleOrder.total_discount)-toNumber(CalculatedVAT.total_vat)) }} 
                                                </span>
                                                
                                            </span>
                                            <span v-else>
                                                {{ MBS.actions.money(toNumber(SaleOrder.total_sold_price?SaleOrder.total_sold_price:SaleOrder.total_price)-toNumber(SaleOrder.all_discounts?SaleOrder.all_discounts:SaleOrder.total_discount)) }} 
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="b-all">
                                        <th colspan="2" class="text-">DISCOUNT</th>
                                        <td colspan="4" class="text-right px-2">{{ MBS.actions.money(SaleOrder.all_discounts?SaleOrder.all_discounts:SaleOrder.total_discount) }}</td>
                                    </tr>
                                    <tr class="b-all">
                                        <th rowspan="5">Tax</th>
                                        <th class="not-f2 px-2">TAX GROUP</th> 
                                        <th colspan="2" class="not-f2">ITEM AMOUNT</th> 
                                        <th colspan="2" class="not-f2">VAT AMOUNT</th>   
                                    </tr> 
                                    <tr class="b-all">
                                        <td class="not-f2 px-2">TAX GROUP A-16.5%</td>
                                        <td colspan="2" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.taxable_a:0)}}</td>
                                        <td colspan="2" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.vat_a:0)}}</td>
                                    </tr>
                                    <tr class="b-all">
                                        <td class="not-f2 px-2">TAX GROUP B-0%</td>
                                        <td colspan="2" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.taxable_b:0)}}</td>
                                        <td colspan="2" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.vat_b:0)}}</td>
                                    </tr>
                                    <tr class="b-all">
                                        <td class="not-f2 px-2">TAX GROUP E-0%</td>
                                        <td colspan="2" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.taxable_c:0)}}</td>
                                        <td colspan="2" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.vat_c:0)}}</td>
                                    </tr> 
                                    <tr class="b-all">
                                        <th class="not-f2 text-left px-2"  >TOTAL VAT</th>
                                        <th colspan="4" class="text-right px-2">{{MBS.actions.money(CalculatedVAT?CalculatedVAT.total_vat:0)}}</th>
                                    </tr> 
                                    <tr class="b-all">
                                        <th colspan="2" class="not-f6">GRAND TOTAL</th>
                                        <th class="text-right not-f6 px-2" colspan="4">{{ MBS.actions.money(SaleOrder.sum_amount) }}</th>
                                    </tr>
                                </table> 
                            </div>  
                        </div> 
                    </v-card>
                    <v-spacer></v-spacer>
                    <div class="my-1"  justify-center> 
                        
                    </div>
                </v-layout>
            </v-card> 
        </v-card>

        <iframe ref="pdfIframe" style="display: none;"></iframe>
        <div ref="pdfContent" style="display: none;">
            <!-- Render PDF content here -->
            <!-- For example, you can render the generated PDF as an image -->
            <img :src="pdfDataUri" alt="PDF Content" />
        </div>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'

    import jsPDF   from "jspdf"
    import autoTable from 'jspdf-autotable'
    export default {
        props:['data','title','report_type','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                DAY_SHIFT_PRINT_REQUEST:null,
                loaded:false,
                pdfDataUri: '',
            } 
        }, 
        created() {
            try { 
                this.MBS.events.$on('PRINT_PDF', this.PRINT_PDF);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){ 
            this.EPSON_SALE_ORDER_REQUEST({
                report_type:this.report_type,
                sale_order:this.Joined_SaleOrder,
            }) 
 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
             
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            SaleOrder(){ 
                let data = this.data 
                return  data
            },
            Joined_SaleOrder(){ 
                let sale = this.SaleOrder  
                let join = this.MBS.actions.JOIN_SALE_ORDER(sale)  
                return join
            }, 
            Joined_SaleOrderItems(){ 
                let order = this.Joined_SaleOrder  
                let join = order?order.joined_items:null  
                return join
            }, 
            CalculatedVAT(){
                let order = this.Joined_SaleOrder
                if(!order){return null}
                return order.calculated_vat
            },  
            PriceExcludeVAT(){
                try { 
                    const calculated_vat = this.CalculatedVAT
                    const vat_exclusive = calculated_vat?calculated_vat.vat_exclusive:null 
                    return (item)=>{ 
                        let sold_price = this.toNumber(item.sold_price)
                        let quantity = this.toNumber(item.quantity)
                        let discount = this.toNumber(item.discount)
                        let price = (sold_price-discount)*quantity 
                        let tax_group_code = item.tax_group_code
                        if (!tax_group_code && item.item) {
                            tax_group_code = item.item.tax_group_code
                            console.log(tax_group_code,'tax_group_code......');
                        }
                        
                        if (calculated_vat && !vat_exclusive) {
                            if (tax_group_code=="B"||tax_group_code=="E") {
                                return price
                            }
                            return price / (1+(16.5/100))
                        }
                        return price 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PriceExcludeVAT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },   
            Company(){ 
                let item = this.Joined_SaleOrder
                return item?item.company:null
            }, 

            FixItemSize(){ 
                return (size,items = this.Joined_SaleOrderItems)=>{ 
                    let item_size = this.MBS.actions.SIZE(items)  
                    if (size<item_size) {
                       return 0 
                    } 
                    return size-item_size 
                } 
            },  
            moment(){ 
                return this.MBS.date.moment
            },  
            today(){ 
                return this.MBS.date.today
            },  
            
             
        },
        methods:{
            async EPSON_SALE_ORDER_REQUEST(payload){ 
                try {   
                    this.inputLoading = true
                    let print_order = await this.MBS.actions.EPSON_SALE_ORDER_REQUEST({
                        ...payload,
                        printer:{
                            paper_size:38
                        }
                    }) 
                    this.DAY_SHIFT_PRINT_REQUEST = print_order?print_order.data:null
                    console.log(this.DAY_SHIFT_PRINT_REQUEST,'DAY_SHIFT_PRINT_REQUEST........');
                    this.inputLoading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_SALE_ORDER_REQUEST',
                        page:PAGE_NAME, 
                    }) 
                    return 
                }   
            },
            IMAGE_LOADED(event){
                this.loaded = true
                this.$emit("image_loaded",event)
                console.log(event,'IMAGE_LOADED.....');
            }, 
            IMAGE_START_LOADING(event){
                this.loaded = false
                this.$emit("image_start_loading",event)
                console.log(event,'IMAGE_START_LOADING.....');
            }, 


            generatePDF() { 
                let Company = this.Company?this.Company:{}
                const document_header = this.title?this.title:'CASH SALE / INVOICE'
                const company_name = Company.company_name

                this.PDF_CREATE_SALES_A4_T1({
                    company:Company,
                    sale_order:this.SaleOrder,
                    MBS:this.MBS
                }).then(res=>{
                    console.log(res,'...............s');
                }).catch(error=>{
                    console.log(error,'............ee');
                })
                return  
            },  
            PDF_CREATE_SALES_A4_T1(payload) { 
                let response = {
                    from:"PDF_CREATE_SALES_A4_T1",
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve, reject) => {
                    try {
                        if (!payload) {
                            reject({
                                ...response,
                                success:false,
                                error:'no payload'
                            })
                        } 
                        const document_header = payload.document_header?payload.document_header:'CASH SALE / INVOICE'
                        const company = payload.company?payload.company:{}
                        const sale_order = payload.sale_order?payload.sale_order:{}
                        const auto_print = payload.auto_print?payload.auto_print:null
                        const save = payload.save?payload.save:null
                        // const document_name = payload.document_name?payload.document_name:this.MBS.actions.TEXT_UP(sale_order.order_type)+" "+this.MBS.actions.NUMBER_SAME_LENGTH(sale_order.order_id,'4')+" ("+sale_order.created_at_+")"
                        let document_name = payload.document_name?payload.document_name:this.MBS.actions.TEXT_UP(sale_order.order_type)+" "+this.MBS.actions.NUMBER_SAME_LENGTH(sale_order.order_id,'4') 


                        console.log(company,sale_order,'---------//');

                        //-----------------------[FUNCTIONS]
                        const MBS = payload.MBS?payload.MBS:{}
                        const toNumber = MBS.actions.toNumber?MBS.actions.toNumber:null
                        const money = MBS.actions.money?MBS.actions.money:null
                        const CalculatedVAT = this.CalculatedVAT
                        // const font_name = "helvetica"
                        const font_name = "times"
                        const doc_x = 9.49
                        const doc_y = 8.46
                        const xp = value=>value*doc_x/100 
                        const yp = value=>value*doc_y/100 
                        const h_1 = 0.4
                        const h_2 = 2.4
                        const h_3 = 3.63
                        const h_4 = 4.36
                        const h_5 = 7.46
                        const doc = new jsPDF("l","in",[9.49,8.46])
 
                        //-----------------------[company data] 
                        const company_name = company.company_name
                        const company_email_address = company.company_email_address
                        const company_phone_number = company.company_phone_number
                        const company_phone_number2 = company.company_phone_number2
                        const company_post_address = company.company_post_address
                        const company_location_address = company.company_location_address
                        const company_tpin = company.company_tpin
                        const company_logo = company.company_logo

                        //-----------------------[DOCUMENT FUNCTIONS]
                        const insert_doc_section = (doc_data,x,y,paragraph,align)=>{
                            doc_data.forEach(item => {
                                doc.text(""+item.title, x, y,align) 
                                y += paragraph
                            }) 
                        }
                        const create_table_items = (items)=>{
                            if (!items) {return null}
                            let table_items = []
                            items.forEach((item,index) => {
                                table_items.push([
                                    //---[No.]
                                    { content: index+1,                      
                                        styles:{ 
                                            halign: 'center', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },

                                    //---[ITEM NAME.]
                                    { content: item.name,                      
                                        styles:{ 
                                            halign: 'left', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },

                                    //---[QUANTITY.]
                                    { content: item.quantity,                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },

                                    //---[PRICE.]
                                    { content: money(item.sold_price),                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },
                                    // { content: money(item.discount),                      
                                    //     styles:{ 
                                    //         halign: 'right', 
                                    //         valign:'middle', 
                                    //         cellPadding:2, 
                                    //         fontStyle: 'normal'
                                    //     }, 
                                    // },
                                
                                    //---[V.A.T.]
                                    { content: (item.tax_group_code?item.tax_group_code:"A"),                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    }, 
                                    
                                    //---[TOTAL.]
                                    { content: money(this.PriceExcludeVAT(item)),                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    }, 
                                    
        
                                ])
                            });
                            return table_items 
                        }
                        const insert_simple_items_table = (items,y,p,list_number)=>{
                            if (!items) {return null}
                            let item_names = []
                            let item_quantities = []
                            let item_prices = []
                            let item_totals = []

                            items.forEach((item,index) => {
                                item_names.push({title:(index+1+list_number)+". "+item.name})
                                item_quantities.push({title:item.quantity})
                                item_prices.push({title:money(item.sold_price)})
                                item_totals.push({title:money(this.PriceExcludeVAT(item))})
                                
                            });  
                            insert_doc_section(item_names,0.35,y,p)
                            insert_doc_section(item_quantities,4.55,y,p,"right")
                            insert_doc_section(item_prices,5.65,y,p,"right")
                            insert_doc_section(item_totals,7.8,y,p,"right")
                           
                        }
                        const insert_doc_table = (table_items,x,y)=>{
                           
                            autoTable(doc, {
                                startY: y, 
                                styles:{ 
                                    halign: 'right', valign:'middle', 
                                    cellPadding: 2,
                                    textColor: 0, 
                                    fontSize:8
                                    // fillColor: [245, 245, 245]
                                }, 
                                columnStyles: { 0: { halign: 'center', textColor: 0, fontStyle: 'bold'} },
                                head: [['NO', 'ITEM NAME', 'QUANTITY', 'PRICE',  ' VAT',  'TOTAL INC']],
                                body: [ 
                                    ...table_items,  
                                ],
                                theme:'grid'
                            }) 
                        }
                        const chunkArray = (array, chunkSize)=>{
                            const chunkedArray = [];
                            for (let i = 0; i < array.length; i += chunkSize) {
                                chunkedArray.push(array.slice(i, i + chunkSize));
                            }
                            return chunkedArray;        
                        }


                        //-----------------------[DOCUMENT HEIGHT]
                        // doc.setFont('calibri', 'normal') 
                        doc.setFont(font_name, 'normal') 
                        doc.setFontSize(14).text(company_name, xp(50), h_1,'center')
        
                        //------------------------[ITEMS DATA]  
                        const s_items = sale_order.joined_items?sale_order.joined_items:sale_order.items
                        const sold_items = this.MBS.actions.JOIN_ITEMS(s_items)
                        const table_items = create_table_items(sold_items)

                        let sub_total = 0
                        if (CalculatedVAT) {
                            if (CalculatedVAT.vat_exclusive) {
                                sub_total = money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)+toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount))
                            } else {
                                sub_total = money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)-toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount)-toNumber(CalculatedVAT.total_vat))
                            }
                        }else{
                            sub_total=  money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)-toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount))
                        }

                        // Header Column
                        const y_customer_h_info = h_2
                        const y_document_h_info = h_2
                        const y_tpin_h_info = h_2
                        
                        // Header Column
                        const x_customer_h_info = 0.49
                        const x_document_h_info_ = 4.49
                        const x_document_h_info = 5.49
                        const x_tpin_h_info = 7 
                        
                        // Header Paragraph Height  
                        const h_customer_h_info = 0.2
                        const h_document_h_info = 0.2
                        const h_tpin_h_info = 0.4
 

                        //----------------[HEAD INFO]
                        const customer_name = sale_order?.customer?.name
                        const phone_number = sale_order?.customer?.phone_number
                        const email_address = sale_order?.customer?.email_address
                        const customer_info_items = [
                            { title: 'Customer Name: '+(customer_name?customer_name:'...')},
                            { title: 'Phone Number: '+(phone_number?phone_number:'...')},
                            { title: 'Email Address: '+(email_address?email_address:'...')},
                        ]   
                        const document_info_items_ = [
                            { title: 'Date: '},
                            { title: 'Type: '},
                            { title: 'No: '},
                        ]  
                        const document_info_items = [
                            { title: ''+sale_order?.local_created_at_date},
                            { title: ''+document_header},
                            { title: ''+sale_order?.order_id},
                        ]  
                        const tpin_info_items = [
                            { title: 'TPIN: '+company_tpin}, 
                            { title: 'OUTLET: '+sale_order.outlet_name}, 
                        ]   
                         
                        ///
                        doc.setFont(font_name, 'normal','bold') 
                        doc.setFont(font_name).setFontSize(10)
                        insert_doc_section(customer_info_items,x_customer_h_info,y_customer_h_info,h_customer_h_info)
                        insert_doc_section(document_info_items_,x_document_h_info_,y_document_h_info,h_document_h_info)
                        insert_doc_section(document_info_items,x_document_h_info,y_document_h_info,h_document_h_info)
                        insert_doc_section(tpin_info_items,x_tpin_h_info,y_tpin_h_info-0.2,h_tpin_h_info*1.8)
                        
                        //ACCOUNT NO.
                        insert_doc_section([{title:sale_order?.receivable_name}],0.2,h_3,1)
                        insert_doc_section([{title:sale_order?.payment_method_name}],3.3,h_3,1)


                        //-------------------------[ ITEM DATA TABLE ]
                        if (sold_items) {
                            const page_list_items_number = 15
                            const chunk_table_items = chunkArray(sold_items,page_list_items_number)

                            console.log(sold_items,'sold_items......');
                            console.log(chunk_table_items,'chunk_table_items......');
                            
                             
                            chunk_table_items.forEach((items,index) => {
                                if (index>0) {
                                    doc.addPage()
                                } 

                                //Insert Items
                                doc.setFont(font_name, 'normal') 
                                doc.setFontSize(8)
                                insert_simple_items_table(items,h_4,0.2,page_list_items_number*index)


                                 // POWERED BY 
                                doc.setFont(font_name, 'bold') 
                                doc.setFont(font_name).setFontSize(9)
                                let powered_by = DATA.APP.POWERED_BY
                                let admin_phone = DATA.APP.ADMIN_PHONE

                                if (false) {
                                    doc.text(powered_by+" "+admin_phone,8.05,doc_y-1.7,null,90) 
                                }
                                
                            });
                            
                        }
         
                          

                        // TOTAL INFORMATION
                        const total_info_items = [
                            { title: 'SUB TOTAL: '}, 
                            { title: 'V.A.T: '}, 
                            { title: 'TOTAL: '}, 
                        ]   
                        const total_info_items_value = [
                            { title: ''+sub_total}, 
                            { title: ''+money(CalculatedVAT?CalculatedVAT.total_vat:0)}, 
                            { title: ''+money(sale_order.sum_amount)}, 
                        ] 
                        
                        const total_vat_a = [
                            { title: 'TAX GROUP A-16.5%'},  
                            { title: 'Item: '+money(CalculatedVAT?CalculatedVAT.taxable_a:0)}, 
                            { title: 'VAT: '+money(CalculatedVAT?CalculatedVAT.vat_a:0)}, 
                        ]   
                        const total_vat_b = [
                            { title: 'TAX GROUP B-0%'},  
                            { title: 'Item: '+money(CalculatedVAT?CalculatedVAT.taxable_b:0)}, 
                            { title: 'VAT: '+money(CalculatedVAT?CalculatedVAT.vat_b:0)}, 
                        ]  
                        const total_vat_c = [
                            { title: 'TAX GROUP E-0%'},  
                            { title: 'Item: '+money(CalculatedVAT?CalculatedVAT.taxable_c:0)}, 
                            { title: 'VAT: '+money(CalculatedVAT?CalculatedVAT.vat_c:0)}, 
                        ] 
                        const discount = [
                            { title: 'DISCOUNT'},   
                            { title: money(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount)}, 
                        ]   
                        const vat_h = 7.67

                       
                         
                        //TOTAL
                        doc.setFont(font_name, 'bold') 
                        doc.setFont(font_name).setFontSize(11)
                        insert_doc_section(total_info_items,5.9,h_5,0.35)
                        insert_doc_section(total_info_items_value,8,h_5,0.35,'right')


                        //  TAX GROUPS + VAT
                        doc.setFont(font_name, 'bold') 
                        doc.setFont(font_name).setFontSize(8)
                        insert_doc_section(total_vat_a,0.48,vat_h,0.16)
                        insert_doc_section(total_vat_b,1.98,vat_h,0.16)
                        insert_doc_section(total_vat_c,3.48,vat_h,0.16)
                        insert_doc_section(discount,4.98,vat_h,0.16)

                       


                        if (save) {
                            document_name = this.today+" kp-"+document_name
                            console.log(document_name,'document_name...');
                            
                            if (auto_print) {
                                doc.autoPrint({variant: 'non-conform'}) 
                                doc.save(`${document_name}.pdf`)
                            } else {
                                doc.save(`${document_name}.pdf`)
                            }
                            
                        } else {
                            if (auto_print) {
                                doc.autoPrint({variant: 'non-conform'})  
                                window.open(doc.output('bloburl'))
                            } else {
                                
                            } 
                        }
         
                        const pdfDataUri = doc.output('datauristring'); 
                        resolve({
                            success:true,
                            pdfDataUri:pdfDataUri,
                            pdf:pdfDataUri,
                            doc:doc,
                        })
                        
                    } catch (error) {
                        reject({
                            ...response,
                            success:false,
                            error:error
                        })
                    } 
                })
            }, 
            PDF_ELECTRA_SALES_QUOTATION(payload) { 
                let response = {
                    from:"PDF_ELECTRA_SALES_QUOTATION",
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve, reject) => {
                    try {
                        if (!payload) {
                            reject({
                                ...response,
                                success:false,
                                error:'no payload'
                            })
                        } 

                        //------------------------[DOCUMENT DATA]
                        const document_header = payload.document_header?payload.document_header:'CASH SALE / INVOICE'
                        const company = payload.company?payload.company:{}
                        const sale_order = payload.sale_order?payload.sale_order:{}
                        const auto_print = payload.auto_print?payload.auto_print:null
                        const save = payload.save?payload.save:null
                        let document_name = payload.document_name?payload.document_name:this.MBS.actions.TEXT_UP(sale_order.order_type)+" "+this.MBS.actions.NUMBER_SAME_LENGTH(sale_order.order_id,'4') 

                        //--COMPANY  
                        const company_name = company.company_name
                        const company_email_address = company.company_email_address
                        const company_phone_number = company.company_phone_number
                        const company_phone_number2 = company.company_phone_number2
                        const company_post_address = company.company_post_address
                        const company_location_address = company.company_location_address
                        const company_tpin = company.company_tpin
                        const company_logo = company.company_logo
                        //--MBS
                        const MBS = payload.MBS?payload.MBS:{}
                        const toNumber = MBS.actions.toNumber?MBS.actions.toNumber:null
                        const money = MBS.actions.money?MBS.actions.money:null
                        const CalculatedVAT = this.CalculatedVAT
                        //--CUSTOMER
                        const customer_name = sale_order?.customer?.name
                        const customer_phone_number = sale_order?.customer?.phone_number
                        const customer_email_address = sale_order?.customer?.email_address
                        //--ITEM DATA 
                        const s_items = sale_order.joined_items?sale_order.joined_items:sale_order.items
                        const sold_items = this.MBS.actions.JOIN_ITEMS(s_items) 

                        let sub_total = 0
                        if (CalculatedVAT) {
                            if (CalculatedVAT.vat_exclusive) {
                                sub_total = money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)+toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount))
                            } else {
                                sub_total = money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)-toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount)-toNumber(CalculatedVAT.total_vat))
                            }
                        }else{
                            sub_total=  money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)-toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount))
                        }

                        // calibri
                        const font_name = "times"
                        //--SIZES
                        const doc_x = 210
                        const doc_y = 297
                        const xp = value=>value*doc_x/100 
                        const yp = value=>value*doc_y/100 
                        const mgl = 10
                        const mgr = 200
                        const mgt = 20
                        const mgb = 280 
                        const h_1 = 60
                        const h_2 = 76
                        const h_3 = 98
                        const h_4 = 110
                        const h_5 = 220
                        const doc = new jsPDF("P","mm",[doc_y,doc_x])
 
                        

                        //-----------------------[DOCUMENT FUNCTIONS]
                        const insert_doc_section = (doc_data,x,y,paragraph,align)=>{
                            doc_data.forEach(item => {
                                doc.text(""+item.title, x, y,align) 
                                y += paragraph
                            }) 
                        } 
                        const inert_document_header = ()=>{
                            doc.setFont(font_name, 'bold') 
                            doc.setFontSize(15).text(company_name, xp(50), mgt,'center') 
                            const company_info = [
                                { title: 'Email: '+company_email_address}, 
                                { title: 'Address: '+company_location_address}, 
                                { title: 'Phone: '+company_phone_number}, 
                                { title: 'TPIN: '+company_tpin}, 
                            ]  
                            doc.setFont(font_name,'normal').setFontSize(11)
                            insert_doc_section(company_info,xp(50),mgt+6,yp(2),'center')
                            doc.addImage("/img/companies/electrasales.png", "PNG", mgl, mgt, 50, 20);

                        }
                        const insert_document_info = ()=>{
                            
                            doc.setFont(font_name, 'bold') 
                            doc.setFontSize(13).text(document_header, xp(50), h_1+6,'center')
                            doc.rect(mgl, h_1, mgr-mgl, 10); // outline header

                            const customer_info_items = [
                                { title: 'Customer Name: '+(customer_name?customer_name:'')},
                                { title: 'Phone Number: '+(customer_phone_number?customer_phone_number:'')},
                                { title: 'Email Address: '+(customer_email_address?customer_email_address:'')},
                            ]   
                            const document_info_items = [
                                { title: 'Date: '+sale_order?.local_created_at_date},
                                { title: 'Outlet: '+sale_order.outlet_name},
                                { title: 'Till: '+sale_order.till_name},
                                { title: 'No: '+sale_order?.order_id},
                            ]   
                            const payment_account = [
                                { title: 'Account'}, 
                                { title: ''+sale_order?.receivable_name}, 
                                { title: ''+sale_order?.payment_method_name},  
                            ]   
                            doc.setFont(font_name, 'normal') 
                            doc.setFont(font_name).setFontSize(10)
                            insert_doc_section(customer_info_items,mgl,h_2,yp(2))
                            insert_doc_section(document_info_items,mgr,h_2,yp(2),'right')
                            insert_doc_section(payment_account,xp(50),h_2,yp(2),'center') 
                        }
                        const insert_simple_items_table = (items,y,p,list_number)=>{
                            if (!items) {return null}
                            let item_number = []
                            let item_names = []
                            let item_quantities = []
                            let item_prices = []
                            let item_discount = []
                            let item_totals = []

                            items.forEach((item,index) => {
                                item_number.push({title:(index+1)+"."})
                                item_names.push({title:item.name})
                                item_quantities.push({title:item.quantity})
                                item_prices.push({title:money(item.sold_price)})
                                item_discount.push({title:money(item.discount)})
                                item_totals.push({title:money(this.PriceExcludeVAT(item))})
                                
                            });  
                            const x_item=19
                            const x_price=118
                            const x_discount=145
                            const x_quantity=163
                            const x_amount=198
                            const y_h=y-8

                            //ADD HEADERS
                            doc.setFont(font_name, 'bold') 
                            doc.setFontSize(8).text("ITEMS NAME",x_item,y_h,'')
                            doc.setFontSize(8).text("PRICE",x_price,y_h,'right')
                            doc.setFontSize(8).text("DISC",x_discount,y_h,'right')
                            doc.setFontSize(8).text("QTY",x_quantity,y_h,'right')
                            doc.setFontSize(8).text("AMOUNT (EX VAT)",x_amount,y_h,'right')

                            doc.line(mgl, y-5, mgr, y-5); 
                            // 
                            

                            doc.setFont(font_name, 'normal') 
                            doc.setFontSize(8)
                            insert_doc_section(item_number,12,y,p)
                            insert_doc_section(item_names,x_item,y,p)
                            insert_doc_section(item_prices,x_price,y,p,"right")
                            insert_doc_section(item_discount,x_discount,y,p,"right")
                            insert_doc_section(item_quantities,x_quantity,y,p,"right")
                            insert_doc_section(item_totals,x_amount,y,p,"right")
 
                           
                        } 
                        const chunkArray = (array, chunkSize)=>{
                            const chunkedArray = [];
                            for (let i = 0; i < array.length; i += chunkSize) {
                                chunkedArray.push(array.slice(i, i + chunkSize));
                            }
                            return chunkedArray;        
                        }

                        //-------------------------[ ITEM DATA TABLE ]
                        if (sold_items) {
                            const page_list_items_number = 20
                            const chunk_table_items = chunkArray(sold_items,page_list_items_number)

                            const sub_size = 42
                            const h_subtotal = mgb-sub_size
                            const h_sub = mgb-sub_size+4
                            const h_cell = n=> h_subtotal+ 5*n
                            const hc = n=> h_sub+ 5*n
                           
                            chunk_table_items.forEach((items,index) => {
                                
                                let table_h = h_4
                                if (index>0) {
                                    table_h = h_2
                                    doc.addPage() 
                                }else{
                                    insert_document_info()
                                } 

                                 
                                inert_document_header()
                                insert_simple_items_table(items,table_h,yp(2),page_list_items_number*index)
                                doc.rect(mgl, table_h-14, mgr-mgl, (mgb-table_h+14)); // outline the table
                                
                                doc.line(mgl+8, table_h-14, mgl+8, h_subtotal);
                                doc.line(121, table_h-14, 121, h_subtotal);
                                doc.line(147, table_h-14, 147, h_subtotal);
                                doc.line(166, table_h-14, 166, h_subtotal);
                                doc.line(94, table_h-14, 94, mgb);


                                 // POWERED BY 
                                doc.setFont(font_name, 'bold') 
                                doc.setFont(font_name).setFontSize(9)
                                let powered_by = DATA.APP.POWERED_BY
                                let admin_phone = DATA.APP.ADMIN_PHONE

                                if (false) {
                                    doc.text(powered_by+" "+admin_phone,8.05,doc_y-1.7,null,90) 
                                }
                                
                            });

                            // TOTAL INFORMATION
                            //SUB-TOTAL
                            doc.line(mgl, h_subtotal, mgr, h_subtotal);
                            doc.setFontSize(8).text("SUB-TOTAL", 53, hc(0),'center')
                            doc.setFontSize(8).text(sub_total, 197, hc(0),'right')
                            
                            //DISCOUNT
                            doc.line(mgl, h_cell(1), mgr, h_cell(1));
                            doc.setFontSize(8).text("DISCOUNT", 53,  hc(1),'center')
                            doc.setFontSize(8).text(money(sale_order.all_discounts?
                                sale_order.all_discounts:sale_order.total_discount), 197,  hc(1),'right')
                            
                                //GROUPS
                            doc.line(mgl, h_cell(2), mgr, h_cell(2));
                            doc.setFontSize(7).text("TAX GROUPS",53,  hc(2),'center')
                            doc.setFontSize(7).text("ITEM AMOUNT", 118,  hc(2),'center')
                            doc.setFontSize(7).text("VAT AMOUNT", 173,  hc(2),'center')

                            doc.setFontSize(7).text("TAX", 13.5, hc(4),'center')
                            doc.line(mgl+8, h_cell(2), mgl+8, h_cell(7));
                            doc.line(147, h_cell(2), 147, h_cell(6));

                            //G
                            doc.line(mgl+8, h_cell(3), mgr, h_cell(3));
                            doc.setFontSize(7).text("TAX GROUP A-16.5%", 20,  hc(3),'left')
                            doc.line(mgl+8, h_cell(4), mgr, h_cell(4));
                            doc.setFontSize(7).text("TAX GROUP B-0%", 20,  hc(4),'left')
                            doc.line(mgl+8, h_cell(5), mgr, h_cell(5));
                            doc.setFontSize(7).text("TAX GROUP E-0%", 20,  hc(5),'left')
                            //AMOUNT
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.taxable_a:0), 144.5, hc(3),'right')
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.taxable_b:0), 144.5,  hc(4),'right')
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.taxable_c:0), 144.5,  hc(5),'right')
                            //VAT
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.taxable_a:0), 197,  hc(3),'right')
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.vat_b:0), 197,  hc(4),'right')
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.vat_c:0), 197,  hc(5),'right')
                            //TOTAL VAT
                            doc.line(mgl+8, h_cell(6), mgr, h_cell(6));
                            doc.setFontSize(7).text("TAX VAT", 20,  hc(6),'left')
                            doc.setFontSize(7).text(money(CalculatedVAT?CalculatedVAT.total_vat:0), 197, hc(6),'right')
                            //GROUND TOTAL
                            doc.line(mgl, h_cell(7), mgr, h_cell(7));
                            doc.setFontSize(7).text("GRAND TOTAL", 53, hc(7),'center')
                            doc.setFontSize(7).text(money(sale_order.sum_amount), 197,  hc(7),'right')
                        }
         
                          
                       

                        

                        


                        // const write_discount_amount = [
                        //     { title: sub_total}, 
                        // ]

                        // const total_info_items = [
                            
                        //     { title: 'V.A.T: '}, 
                        //     { title: 'TOTAL: '}, 
                        // ]   
                        // const total_info_items_value = [
                        //     { title: ''+sub_total}, 
                        //     { title: ''+money(CalculatedVAT?CalculatedVAT.total_vat:0)}, 
                        //     { title: ''+money(sale_order.sum_amount)}, 
                        // ] 
                        
                        // const total_vat_a = [
                        //     { title: 'TAX GROUP A-16.5%'},  
                        //     { title: 'Item: '+money(CalculatedVAT?CalculatedVAT.taxable_a:0)}, 
                        //     { title: 'VAT: '+money(CalculatedVAT?CalculatedVAT.vat_a:0)}, 
                        // ]   
                        // const total_vat_b = [
                        //     { title: 'TAX GROUP B-0%'},  
                        //     { title: 'Item: '+money(CalculatedVAT?CalculatedVAT.taxable_b:0)}, 
                        //     { title: 'VAT: '+money(CalculatedVAT?CalculatedVAT.vat_b:0)}, 
                        // ]  
                        // const total_vat_c = [
                        //     { title: 'TAX GROUP E-0%'},  
                        //     { title: 'Item: '+money(CalculatedVAT?CalculatedVAT.taxable_c:0)}, 
                        //     { title: 'VAT: '+money(CalculatedVAT?CalculatedVAT.vat_c:0)}, 
                        // ] 
                        // const discount = [
                        //     { title: 'DISCOUNT'},   
                        //     { title: money(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount)}, 
                        // ]   
                        // const vat_h = 7.67

                       
                         
                        // //TOTAL
                        // doc.setFont(font_name, 'bold') 
                        // doc.setFont(font_name).setFontSize(11)
                        // insert_doc_section(total_info_items,5.9,h_5,0.35)
                        // insert_doc_section(total_info_items_value,8,h_5,0.35,'right')


                        // //  TAX GROUPS + VAT
                        // doc.setFont(font_name, 'bold') 
                        // doc.setFont(font_name).setFontSize(8)
                        // insert_doc_section(total_vat_a,0.48,vat_h,0.16)
                        // insert_doc_section(total_vat_b,1.98,vat_h,0.16)
                        // insert_doc_section(total_vat_c,3.48,vat_h,0.16)
                        // insert_doc_section(discount,4.98,vat_h,0.16)

                       


                        if (save) {
                            document_name = this.today+" kp-"+document_name
                            console.log(document_name,'document_name...');
                            
                            if (auto_print) {
                                doc.autoPrint({variant: 'non-conform'}) 
                                doc.save(`${document_name}.pdf`)
                            } else {
                                doc.save(`${document_name}.pdf`)
                            }
                            
                        } else {
                            if (auto_print) {
                                doc.autoPrint({variant: 'non-conform'})  
                                window.open(doc.output('bloburl'))
                            } else {
                                
                            } 
                        }
         
                        const pdfDataUri = doc.output('datauristring'); 
                        resolve({
                            success:true,
                            pdfDataUri:pdfDataUri,
                            pdf:pdfDataUri,
                            doc:doc,
                        })
                        
                    } catch (error) {
                        reject({
                            ...response,
                            success:false,
                            error:error
                        })
                    } 
                })
            }, 
            PDF_CREATE_SALES_A4_T2(payload) { 
                let response = {
                    from:"PDF_CREATE_SALES_A4_T2",
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve, reject) => {
                    try {
                        if (!payload) {
                            reject({
                                ...response,
                                success:false,
                                error:'no payload'
                            })
                        } 
                        const document_header = payload.document_header?payload.document_header:'CASH SALE / INVOICE'
                        const company = payload.company?payload.company:{}
                        const sale_order = payload.sale_order?payload.sale_order:{}
                        const auto_print = payload.auto_print?payload.auto_print:null
                        const save = payload.save?payload.save:null
                        const document_name = payload.document_name?payload.document_name:this.MBS.actions.TEXT_UP(sale_order.order_type)+" "+this.MBS.actions.NUMBER_SAME_LENGTH(sale_order.order_id,'4')+" ("+sale_order.created_at_+")"


                        console.log(company,sale_order,'---------//');

                        //-----------------------[FUNCTIONS]
                        const MBS = payload.MBS?payload.MBS:{}
                        const toNumber = MBS.actions.toNumber?MBS.actions.toNumber:null
                        const money = MBS.actions.money?MBS.actions.money:null
                        const CalculatedVAT = this.CalculatedVAT
                        const doc = new jsPDF()
                        const font_name = "helvetica"

                        //-----------------------[company data] 
                        const company_name = company.company_name
                        const company_email_address = company.company_email_address
                        const company_phone_number = company.company_phone_number
                        const company_phone_number2 = company.company_phone_number2
                        const company_post_address = company.company_post_address
                        const company_location_address = company.company_location_address
                        const company_tpin = company.company_tpin
                        const company_logo = company.company_logo
        
                        //------------------------[ITEMS DATA]  
                        const s_items = sale_order.joined_items?sale_order.joined_items:sale_order.items
                        const sold_items = this.MBS.actions.JOIN_ITEMS(s_items)
                        let items = []
                        if (sold_items) { 
                            sold_items.forEach((item,index) => {
                                items.push([
                                    { content: index+1,                      
                                        styles:{ 
                                            halign: 'center', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },
                                    { content: item.name,                      
                                        styles:{ 
                                            halign: 'left', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },
                                    { content: money(item.sold_price),                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },
                                    { content: money(item.discount),                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },
                                    { content: item.quantity,                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    },
                                    { content: money(
                                        this.PriceExcludeVAT(item))+"  -  "+(item.tax_group_code?item.tax_group_code:"A"),                      
                                        styles:{ 
                                            halign: 'right', 
                                            valign:'middle', 
                                            cellPadding:2, 
                                            fontStyle: 'normal'
                                        }, 
                                    }, 
                                     
        
                                ])
                            });
                            
                        }
        
                        //-------------------------[DAM ITEMS]
                        let item_size = MBS.actions.SIZE(items)
                        let dam_item = []
                        // for (let index = 0; index < 14-item_size; index++) {
                            dam_item.push([
                                { content: ' ',  rowHeight:"500",              
                                    styles:{ 
                                        halign: 'center', 
                                        valign:'middle', 
                                        cellPadding:2, 
                                        fontStyle: 'normal',
                                        height:"500"
                                    }, 
                                },
                                { content: '',                      
                                    styles:{ 
                                        halign: 'left', 
                                        valign:'middle', 
                                        cellPadding:2, 
                                        fontStyle: 'normal'
                                    }, 
                                },
                                { content: '',                      
                                    styles:{ 
                                        halign: 'right', 
                                        valign:'middle', 
                                        cellPadding:2, 
                                        fontStyle: 'normal'
                                    }, 
                                },
                                { content: '',                      
                                    styles:{ 
                                        halign: 'right', 
                                        valign:'middle', 
                                        cellPadding:2, 
                                        fontStyle: 'normal'
                                    }, 
                                },
                                { content: '',                      
                                    styles:{ 
                                        halign: 'right', 
                                        valign:'middle', 
                                        cellPadding:2, 
                                        fontStyle: 'normal'
                                    }, 
                                },
                                { content: '',                      
                                    styles:{ 
                                        halign: 'right', 
                                        valign:'middle', 
                                        cellPadding:2, 
                                        fontStyle: 'normal'
                                    }, 
                                },  
                            ])
                        // }
        
        
                        //-------------------------[TABLE DATA]
                        doc.autoTableSetDefaults({
                            headStyles: { fillColor: "#bfc0c2"}
                        })
                        doc.setFont(font_name, 'normal') 
                        doc.setFontSize(12).text(company_name, 90, 15,)
        
                        const col1 = 15
                        const col2 = 70
                        const col3 = 125
        
                        doc.text('', col1, 30)
                        doc.text('', col2, 30)
                        doc.text('', col3, 30)
        
                        const column1 = 15
                        const column3 = 145
        
                        doc.text('', column1, 30)
                        doc.text('', column3, 30)
                        // doc.text('', col3, 30)
        
                        doc.setFont(font_name).setFontSize(10)
        
                        const imageURL = "/logo/companies/electro_sales.png" 
                        const no_img = "/system/no_img.png" 
                        const x = 15
                        const y = 20 
                        const width = 35 
                        const height = 18 
        
                        doc.addImage(no_img, "PNG", x, y, width, height)            
        
                        //-----------[COMPANY ADDRESS]
                        
                        const company_address_items = [
                            { item: ' ', description: 'Email: '+company_email_address },
                            { item: ' ', description: 'Address: '+company_post_address },
                            { item: ' ', description: 'Phone Number: '+company_phone_number }, 
                            { item: ' ', description: 'TPin: '+company_tpin},
                        ]
        
                        let startY = 25
                        const lineHeight = 5 
                        company_address_items.forEach(item => { 
                            doc.text(item.description, col2, startY)  
                            startY += lineHeight
                        }) 
                        doc.line(col1, 50, col3 + 70, 50) 
                        // doc.setFontSize(10).text(document_header, 50, 56,) 
                        doc.setFontSize(10).text(document_header, 100, 56, null, null, "center");
                        doc.line(col1, 60, col3 + 70, 60)
        
                        //----------------[CUSTOMER INFO]
                        const customer_info_items = [
                            { item: 'Customer Name:Unknown', description: '', item0: 'Date: '+sale_order.local_created_at_},
                            { item: 'Phone Number:', description: '.', item0: 'Till: '+sale_order.till_name },
                            { item: 'Email Address:', description: '', item0: this.MBS.actions.TEXT_UP11(sale_order.order_type)+' Number: '+this.MBS.actions.NUMBER_SAME_LENGTH(sale_order.order_id,'4')},
                        ] 
                        let yAxis = 70
                        const paragraphHeight = 5 
                        customer_info_items.forEach(item => {
                            doc.text(item.item, column1, yAxis)
                            doc.text(item.description, col2, yAxis)
                            doc.text(item.item0, column3, yAxis)
        
                            yAxis += paragraphHeight
                        }) 
        
        
        
        
                        let tax_group_font = 8
                        let sub_total = 0
                        if (CalculatedVAT) {
                            if (CalculatedVAT.vat_exclusive) {
                                sub_total = money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)+toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount))
                            } else {
                                sub_total = money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)-toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount)-toNumber(CalculatedVAT.total_vat))
                            }
                        }else{
                            sub_total=  money(toNumber(sale_order.total_sold_price?sale_order.total_sold_price:sale_order.total_price)-toNumber(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount))
                        }sale_order
        
        
                        autoTable(doc, {
                            startY: 90, 
                            styles:{ 
                                halign: 'right', valign:'middle', 
                                cellPadding: 2,
                                textColor: 0, 
                                fontSize:8
                                // fillColor: [245, 245, 245]
                            }, 
                            columnStyles: { 0: { halign: 'center', textColor: 0, fontStyle: 'bold'} },
                            head: [['NO', 'ITEM NAME', 'PRICE', 'DISC', 'QTY', 'AMOUNT (EX VAT)']],
                            body: [
        
                                ...items, 
                                ...dam_item, 

                                //----------------[SUB-TOTAL]
                                [
                                    { content: 'SUB-TOTAL', colSpan: 2,                      
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold'
                                        }, 
                                    },
                                    { content:sub_total, colSpan: 4,                      
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: ''
                                        }, 
                                    }, 
                                ],   
                                
                                //----------------[DISCOUNT]
                                [
                                    { content: 'DISCOUNT', colSpan: 2,                      
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold'
                                        }, 
                                    },
                                    { content: money(sale_order.all_discounts?sale_order.all_discounts:sale_order.total_discount), colSpan: 4,                      
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: ''
                                        }, 
                                    }, 
                                ], 

                                //-----------------[TAX]
                                [
                                    { content: 'TAX', rowSpan: 5,                      
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold'
                                        }, 
                                    },
                                    { content: 'TAX GROUP',                       
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold',fontSize:tax_group_font
                                        }, 
                                    },
                                    { content: 'ITEM AMOUNT', colSpan: 2,                      
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold',fontSize:tax_group_font
                                        }, 
                                    }, 
                                    { content: 'VAT AMOUNT', colSpan: 2,                      
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold',fontSize:tax_group_font
                                        }, 
                                    }, 
                                ],   
                                [
                                    { content: 'TAX GROUP A-16.5%',                     
                                        styles:{ 
                                            halign: 'left', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    },
                                    { content:money(CalculatedVAT?CalculatedVAT.taxable_a:0),   colSpan: 2,                
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    }, 
                                    { content:money(CalculatedVAT?CalculatedVAT.vat_a:0),   colSpan: 2,                
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    }, 
                                ],  
                                [
                                    { content: 'TAX GROUP B-0%',                 
                                        styles:{ 
                                            halign: 'left', valign:'middle', 
                                            cellPadding:2, fontStyle: '',fontSize:tax_group_font
                                        }, 
                                    },
                                    { content:money(CalculatedVAT?CalculatedVAT.taxable_b:0), colSpan: 2,                  
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    }, 
                                    { content:money(CalculatedVAT?CalculatedVAT.vat_b:0),  colSpan: 2,                 
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    }, 
                                ], 
                                [
                                    { content: 'TAX GROUP E-0%',                 
                                        styles:{ 
                                            halign: 'left', valign:'middle', 
                                            cellPadding:2, fontStyle: '',fontSize:tax_group_font
                                        }, 
                                    },
                                    { content:money(CalculatedVAT?CalculatedVAT.taxable_c:0),  colSpan: 2,                 
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    }, 
                                    { content:money(CalculatedVAT?CalculatedVAT.vat_c:0),   colSpan: 2,                
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal',fontSize:tax_group_font
                                        }, 
                                    }, 
                                ],  
                                [
                                    { content: 'TOTAL VAT',                 
                                        styles:{ 
                                            halign: 'left', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold',fontSize:tax_group_font
                                        }, 
                                    },
                                    { content:money(CalculatedVAT?CalculatedVAT.total_vat:0),    colSpan: 4,               
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'normal'
                                        }, 
                                    },  
                                ],   
                                [
                                    { content: 'GRAND TOTAL', colSpan: 2,                      
                                        styles:{ 
                                            halign: 'center', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold'
                                        }, 
                                    },
                                    { content:money(sale_order.sum_amount), colSpan: 4,                      
                                        styles:{ 
                                            halign: 'right', valign:'middle', 
                                            cellPadding:2, fontStyle: 'bold'
                                        }, 
                                    },
                                    
                                ]           
                            ],
                            theme:'grid'
                        }) 


                        if (save) {
                            if (auto_print) {
                                doc.autoPrint({variant: 'non-conform'}) 
                                doc.save(`${document_name}.pdf`)
                            } else {
                                doc.save(`${document_name}.pdf`)
                            }
                            
                        } else {
                            if (auto_print) {
                                doc.autoPrint({variant: 'non-conform'})  
                                window.open(doc.output('bloburl'))
                            } else {
                                
                            } 
                        }
         
                        const pdfDataUri = doc.output('datauristring'); 
                        resolve({
                            success:true,
                            pdfDataUri:pdfDataUri,
                            pdf:pdfDataUri,
                            doc:doc,
                        })
                        
                    } catch (error) {
                        reject({
                            ...response,
                            success:false,
                            error:error
                        })
                    } 
                })
            }, 

            PRINT_PDF(action){
                let Company = this.Company?this.Company:{}
                const document_header = this.title?this.title:'CASH SALE / INVOICE'  
                const sale_order = this.SaleOrder
                const action_request = sale_order?.order_type!="quotation"?
                    this.PDF_CREATE_SALES_A4_T1:this.PDF_ELECTRA_SALES_QUOTATION
                action_request({
                    document_header:document_header,
                    company:Company,
                    sale_order:this.SaleOrder,
                    MBS:this.MBS,
                    ...action
                }).then(res=>{
                    console.log(res,'...............s');
                    this.MBS.events.$emit("PRINT_PDF_FINISH",res)
                }).catch(error=>{
                    console.log(error,'............ee');
                    this.MBS.events.$emit("PRINT_PDF_FINISH",error)
                })
            }
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('PRINT_PDF', this.PRINT_PDF);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{
            
            mbs_dialog(value){ 
            },  
            Joined_SaleOrder(value){ 
                this.EPSON_SALE_ORDER_REQUEST({
                    report_type:this.report_type,
                    sale_order:value,
                }) 
            },  
            loaded(value){ 
                this.$emit("input",value)
            },   
        } 
    }
</script>

<style>
.custom-table {
  width: 100%; /* Example: Set table width to 100% */
  border-collapse: collapse; /* Optional: Collapses table borders */
  font-family: Arial, sans-serif; /* Change the font family as needed */
}

.custom-table th,
.custom-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

/* You can also style specific table elements if needed */
.custom-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-table td {
  /* Additional styles for table cells */
}

    /* .my-table table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        padding:5px;
    } */

    
    /* .summary_table table{
        border-collapse: collapse; 
        border: 1px solid black;
    }
    .summary_table th{ 
        border: 1px solid black; 
    }
    .summary_table td{ 
        border: 1px solid black;
    } */
    
    
    
    .m-font{  
        font-size: 10px !important;
        color:red;
    }
    
    .items_table{  
        
    }
    .items_table span{    
    }
    .items_table table{
        border-collapse: collapse; 
        border: 1px solid black;
    }
    .items_table th{ 
        border: 1px solid black; 
    }
    .items_table td{ 
        /* border: 1px solid black; */
        border-left: 1px solid black;
    }

    .b-all{ 
        border: 1px solid black !important;  
    }
    .b-all th{ 
        border: 1px solid black;  
    }
    .b-all td{ 
        border: 1px solid black;  
    }
    /* 
    .b-x th, td{ 
        border-left: 1px solid black;  
        border-right: 1px solid black;  
    }
    .b-y{ 
        border-top: 1px solid black;  
        border-bottom: 1px solid black;  
    }

    .no_table{  
    }
    .no_table table th{
        border-collapse: collapse; 
        border: none;
    }  */
</style>
